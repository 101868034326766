import {
    AfterViewInit,
    Component,
    ElementRef,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { UsersQuery } from '../../../../shared/state/users/users.query';
import { User } from '../../../../shared/models/user.model';
import { UsersService } from '../../../../shared/services/users.service';
import { ID } from '@datorama/akita';
import { cloneDeep } from 'lodash-es';
import { UserRoleColor } from '../../../../../user/enums/user-role-color.enum';
import { UserRoles } from '../../../../../user/enums/user-roles.enum';
import { UserStates } from '../../../../../user/enums/user-states.enum';
import { Role } from '../../../../../editor/editor/enums/role.enum';
import { UsersUtilsService } from '../../../../../user/services/users-utils.service';
import { map } from 'rxjs/operators';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { UsersState } from '../../../../shared/state/users/users.store';
import { KeyValueOption } from '../../../../shared/models/key-value-option.model';

@Component({
    selector: 'elias-form-element-person',
    templateUrl: './form-person.component.html',
    styleUrls: ['./form-person.component.scss'],
})
export class FormPersonComponent extends BasicFormElementComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() disabledOptions;
    @Input() multiple = false;
    @Input() options: KeyValueOption[];
    @Input() settings;

    filteredUser$: Observable<User[]>;
    selectedUsers: ID[] = [];
    filter = true;
    chiefEditorIds;
    userRoleColor = UserRoleColor;
    userRoleOptions: KeyValueOption[];
    activeOptions: KeyValueOption[];
    renderType = 'normal';
    ObjectKeys = Object.keys;
    sort = false;

    private userFilters: AkitaFiltersPlugin<UsersState>;

    @ViewChild('filterName') filterName: ElementRef;

    constructor(
        public usersQuery: UsersQuery,
        private fb: FormBuilder,
        private usersService: UsersService,
        private usersUtilService: UsersUtilsService,
        injector: Injector
    ) {
        super(injector);
        this.userFilters = new AkitaFiltersPlugin<UsersState>(this.usersQuery);
    }

    ngOnInit() {
        super.ngOnInit();

        this.usersService.removeAllFilter(this.userFilters);

        if (this.initValue) {
            this.selectedUsers = cloneDeep(this.initValue);
        } else {
            if (Object.keys(this.settings).length > 0) {
                if (this.settings['requiredValue']) {
                    const user = this.usersQuery.getEntity(this.settings['requiredValue']);
                    if (user && !user.roles.includes(Role.SuperAdmin))
                        this.selectedUsers = this.settings['requiredValue'];
                }
            }
        }
        this.formGroup.get(this.name).setValue(this.selectedUsers);

        if (this.disabledOptions) this.chiefEditorIds = this.disabledOptions;

        this.userRoleOptions = Object.keys(UserRoles).map((userRoles) => {
            return { key: userRoles, value: this.translateService.instant(UserRoles[userRoles]) } as KeyValueOption;
        });
        this.activeOptions = Object.keys(UserStates).map((state) => {
            return { key: state, value: this.translateService.instant(UserStates[state]) } as KeyValueOption;
        });
        if (Object.keys(this.settings).length > 0) {
            if (this.settings['renderType']) this.renderType = this.settings['renderType'];
            if (this.settings['sort']) this.sort = this.settings['sort'];
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['disabledOptions'].currentValue !== undefined &&
            changes['disabledOptions'].currentValue.length != 0
        ) {
            this.disabledOptions = changes['disabledOptions'].currentValue;
            this.chiefEditorIds = this.disabledOptions;
        }
    }

    ngAfterViewInit() {
        if (this.sort) {
            this.filteredUser$ = this.usersService
                .selectAll(this.userFilters)
                .pipe(map((user) => user.sort((user1, user2) => this.isSelected(user1, user2))));
        } else {
            this.filteredUser$ = this.usersService.selectAll(this.userFilters);
        }
    }

    isSelected(user1: User, user2: User) {
        const index = this.selectedUsers.indexOf(user1.id);
        if (index > -1) {
            return -1;
        }
        return 1;
    }

    toggleActivation(user: User) {
        if (user.roles.includes(Role.SuperAdmin)) {
            if (this.name == 'chiefEditors') {
                this.flashMessageService.showTranslatedError('user.edit.superadminselectionerrorChiefEditor');
            } else {
                this.flashMessageService.showTranslatedError('user.edit.superadminselectionerrorEditor');
            }
        } else {
            if (this.multiple) {
                if (user.id == this.usersQuery.getLoggedInUserId()) {
                    const index = this.selectedUsers.indexOf(user.id);
                    if (index == -1) {
                        this.selectedUsers.push(user.id);
                    } else if (this.isAdmin(user)) {
                        this.selectedUsers.splice(index, 1);
                    }
                } else {
                    const index = this.selectedUsers.indexOf(user.id);
                    if (index > -1) {
                        this.selectedUsers.splice(index, 1);
                    } else {
                        this.selectedUsers.push(user.id);
                    }
                }
            } else {
                this.selectedUsers = [user.id];
            }
            this.formGroup.get(this.name).setValue(this.selectedUsers);
        }
    }

    isActive(user: User) {
        if (this.multiple) {
            const index = this.selectedUsers.indexOf(user.id);
            return index > -1;
        } else {
            return this.selectedUsers.length > 0 && this.selectedUsers[0] == user.id;
        }
    }

    isChiefEditor(user) {
        if (this.disabledOptions) {
            for (let i = 0; i < this.chiefEditorIds.length; i++) {
                if (user.id === this.chiefEditorIds[i]) return true;
            }
            return false;
        }
        return false;
    }

    onOptionSelected(event) {
        if (event) {
            this.usersService.setRoleFilter(event, this.userFilters);
        } else {
            this.usersService.removeFilter('role', this.userFilters);
        }
    }

    applyFilter(filterValue: string) {
        this.usersService.removeFilter('search', this.userFilters);
        if (filterValue) {
            this.usersService.setSearchFilter(filterValue, this.userFilters);
        } else {
            this.usersService.removeFilter('search', this.userFilters);
            this.filterName.nativeElement.value = '';
            this.filteredUser$ = this.usersService.selectAll(this.userFilters);
        }
    }

    trackByItemId(index, item) {
        return item.id;
    }

    trackByIndex(index, item) {
        return index;
    }

    isSuperAdmin(user) {
        return user.roles.includes(Role.SuperAdmin);
    }

    isAdmin(user) {
        return user.roles.includes(Role.Admin);
    }
}
