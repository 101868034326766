import { Injectable } from '@angular/core';
import { FormConfig } from '../../../modules/forms/models/form-config.model';
import { AssetCollectionsQuery } from '../../../modules/shared/state/asset-collections/asset-collections.query';

@Injectable({
    providedIn: 'root',
})
export class AssetForm {
    constructor(private assetCollectionsQuery: AssetCollectionsQuery) {}

    public getConfiguration(): FormConfig {
        return {
            elements: [
                {
                    name: 'name',
                    type: 'text',
                    required: true,
                    width: 100,
                    options: [],
                    settings: {},
                    validators: [
                        {
                            name: 'maxLength',
                            options: { length: 255 },
                        },
                    ],
                    show: [],
                },
                {
                    name: 'assetCollections',
                    type: 'pills',
                    required: false,
                    width: 100,
                    options: this.getAssetCollectionOptions(),
                    settings: { multiple: true, category: 'single' },
                    validators: [],
                    show: [],
                    hint: 'publication.hint.category',
                },
            ],
        };
    }

    private getAssetCollectionOptions(): { key: string; value: string; color: string }[] {
        return this.assetCollectionsQuery.getAll().map((ac) => ({ key: ac.id, value: ac.name, color: ac.color }));
    }
}
