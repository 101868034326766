import { FilterOperators } from '../enums/filter-operators.enum';
import { QueryParameters } from '../models/query-parameters.model';
import { SortingDirection } from '../../shared/enums/sorting-direction.enum';

interface FilterQueryOption {
    property: string;
    value: string;
    operator?: FilterOperators;
}

interface SortQueryOption {
    property: string;
    direction: SortingDirection;
}

interface PageQueryOption {
    number?: number;
    size?: number;
}

export class QueryParametersBuilder {
    private customParams: QueryParameters = {};
    private filters: { [property: string]: FilterQueryOption } = {};
    private sortings: { [property: string]: SortQueryOption } = {};
    private page: PageQueryOption = {};
    private search: string = '';

    public addFilter(property: string, value?: string | string[] | null, operator?: FilterOperators): void {
        if (value === null || value === undefined) {
            return;
        }

        if (Array.isArray(value) && value.length === 0) {
            return;
        }

        if (Array.isArray(value)) {
            value = value.join(',');
        }

        this.filters[property] = {
            property,
            value,
            operator,
        };
    }

    public addSorting(property: string | null, direction: SortingDirection | null): void {
        if (property === null || direction === null) {
            return;
        }

        this.sortings[property] = {
            property,
            direction,
        };
    }

    public addSearch(value?: string): void {
        this.search = value || '';
    }

    public addPage(number: number | null, size?: number | null) {
        this.page.number = number ?? undefined;
        this.page.size = size ?? undefined;
    }

    public addCustomParam(property: string, value: string | number): void {
        this.customParams[property] = value;
    }

    public getQueryOptions(): QueryParameters {
        return {
            ...this.buildFilters(),
            ...this.buildSortings(),
            ...this.buildPage(),
            ...this.buildSearch(),
            ...this.customParams,
        };
    }

    private buildFilters(): QueryParameters {
        const options: QueryParameters = {};

        Object.values(this.filters).forEach(({ property, operator, value }) => {
            if (!operator) {
                options[`filter[${property}]`] = value;
                return;
            }

            options[`filter[${property}][value]`] = value;
            options[`filter[${property}][operator]`] = operator;
        });

        return options;
    }

    private buildSortings(): QueryParameters {
        const segments: string[] = [];

        Object.values(this.sortings).forEach(({ property, direction }) => {
            const segment = direction === SortingDirection.Asc ? property : `-${property}`;
            segments.push(segment);
        });

        if (segments.length === 0) {
            return {};
        }

        return { sort: segments.join(',') };
    }

    private buildPage(): QueryParameters {
        const options: QueryParameters = {};

        const { number, size } = this.page;

        if (number) {
            options['page[number]'] = number;
        }

        if (size) {
            options['page[size]'] = size;
        }

        return options;
    }

    private buildSearch(): QueryParameters {
        if (this.search === '') {
            return {};
        }

        return { search: this.search };
    }
}
