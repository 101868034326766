import { Injectable } from '@angular/core';
import { LocksService } from './locks.service';
import { NodesStore } from '../state/nodes/nodes.store';
import { Section } from '../models/section.model';
import { SectionsStore } from '../state/sections/sections.store';

@Injectable()
export class EditorService {
    constructor(
        private locksService: LocksService,
        private nodesStore: NodesStore,
        private sectionsStore: SectionsStore
    ) {}

    resetStores(section: Section) {
        if (section && section.id) {
            this.locksService.deleteLock(section.id).subscribe();
            this.sectionsStore.removeActive(section.id);
        }

        // We do not reset the store here anymore, it was moved to the PublicationGroupOverviewResolver in order to
        // make history filter work.
        // TODO: Rename the method to match what it is doing and check if logic can be improved
        this.nodesStore.reset();
    }
}
