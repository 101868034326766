import { Injectable } from '@angular/core';
import { ImagesStore } from '../state/images/images.store';
import { AssetsStore } from '../../../modules/shared/state/assets/assets.store';
import { ChartsStore } from '../../editor/state/charts/charts.store';
import { PropertyEditorStore } from '../../editor/state/property-editor/property-editor.store';
import { NodesStore } from '../../editor/state/nodes/nodes.store';
import { PreviewStore } from '../../editor/state/preview/preview.store';
import { AssetStoragesStore } from '../../asset-browser/state/asset-storages/asset-storages.store';
import { StylesheetStore } from '../../editor/state/stylesheet/stylesheet.store';
import { SectionsStore } from '../../editor/state/sections/sections.store';

@Injectable({
    providedIn: 'root',
})
export class EditorContainerService {
    constructor(
        private assetStorageStore: AssetStoragesStore,
        private assetStore: AssetsStore,
        private chartsStore: ChartsStore,
        private imagesStore: ImagesStore,
        private nodesStore: NodesStore,
        private previewsStore: PreviewStore,
        private propertyEditorStore: PropertyEditorStore,
        private sectionsStore: SectionsStore,
        private stylesheetStore: StylesheetStore
    ) {}

    resetStore() {
        this.imagesStore.reset();
        this.assetStore.reset();
        this.chartsStore.reset();
        this.nodesStore.reset();
        this.previewsStore.reset();
        this.propertyEditorStore.reset();
        this.assetStorageStore.reset();
        this.stylesheetStore.reset();
        this.sectionsStore.reset();
    }
}
