import { Component, Injector, Input, OnInit } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';
import { ID } from '@datorama/akita';
import { cloneDeep } from 'lodash-es';

@Component({
    selector: 'elias-form-element-pills',
    templateUrl: './form-pills.component.html',
    styleUrls: ['./form-pills.component.scss'],
})
export class FormPillsComponent extends BasicFormElementComponent implements OnInit {
    @Input() options: any;
    @Input() settings;

    category = 'multiple';
    multiple = true;
    selectedValues: ID | ID[];
    headings = [
        { head: 'general', count: 0 },
        { head: 'publication-type', count: 0 },
    ];
    hover;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.initValue) {
            this.selectedValues = cloneDeep(this.initValue);
        }
        if (!this.selectedValues) {
            this.selectedValues = [];
        }
        this.formGroup.get(this.name).setValue(this.selectedValues);

        if (Object.keys(this.settings).length > 0) {
            this.multiple = this.settings['multiple'];
            this.category = this.settings['category'];
        }

        this.headings.forEach((value, index) => {
            this.headings[index]['count'] = Object.values(this.options).filter(
                (val) => val['type'] === value['head']
            ).length;
        });
    }

    toggleActivation(option) {
        if (Object.keys(this.settings).length > 0 && this.multiple && this.selectedValues) {
            const index = (this.selectedValues as ID[]).indexOf(option.key);
            if (index > -1) {
                (this.selectedValues as ID[]).splice(index, 1);
            } else {
                (this.selectedValues as ID[]).push(option.key);
            }
        } else {
            this.selectedValues = this.selectedValues == option.key ? null : option.key;
        }
        this.formGroup.get(this.name).setValue(this.selectedValues);
    }

    isActive(option) {
        if (Object.keys(this.settings).length > 0 && this.multiple && this.selectedValues) {
            const index = (this.selectedValues as ID[]).indexOf(option.key);
            return index > -1;
        } else {
            return this.selectedValues == option.key;
        }
    }

    checkLastAvailable(option) {
        return Array.isArray(this.selectedValues) && this.selectedValues.length == 1 && this.isActive(option);
    }

    trackByIndex(index, item) {
        return index;
    }
}
