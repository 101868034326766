import { Component, HostBinding, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ITreeOptions, TreeComponent, TreeNode } from '@circlon/angular-tree-component';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, interval, Observable, of } from 'rxjs';
import { Section } from '../../models/section.model';
import {
    AspectRatiosService,
    ChartsService,
    CycleService,
    DocumentService,
    EditorService,
    LocksService,
    PreviewService,
    PropertyEditorService,
    SectionsService,
} from '../../services';
import { Node } from '../../models/node/node.model';
import { RequestDto } from '../../models/dto/request-dto.model';
import { TreeService } from '../../services/tree.service';
import { SelectedElement } from '../../models/selected-element.model';
import { Subscription } from 'rxjs/Subscription';
import { TitlePipeReplacerService } from '../../services/utilities/title-pipe-replacer.service';
import { PropertyEditorComponent } from '../../components/property-editor/property-editor.component';
import { SectionsQuery } from '../../state/sections/sections.query';
import { PreviewHtmlComponent } from '../../components/preview-html/preview-html.component';
import { NodesQuery } from '../../state/nodes/nodes.query';
import { PreviewQuery } from '../../state/preview/preview.query';
import { PropertyEditorQuery } from '../../state/property-editor/property-editor.query';
import { LocksQuery } from '../../state/locks/locks.query';
import { delay, filter, first, map, tap } from 'rxjs/operators';
import { PublicationsQuery } from '../../../../publication/state/publications/publications.query';
import { NodePresetsService } from '../../../../modules/shared/services/node-presets.service';
import { NodePresetsQuery } from '../../../../modules/shared/state/node-presets/node-presets.query';
import { NodePreset } from '../../../../modules/shared/models/node-preset.model';
import { ChannelsService } from '../../../../modules/shared/services/channels.service';
import { AspectRatiosQuery } from '../../../../modules/shared/state/aspect-ratios/aspect-ratios.query';
import { Publication } from '../../../../publication/models/publication.model';
import { MatDialog } from '@angular/material/dialog';
import { UiService } from '../../../../modules/core/services/ui.service';
import { ID } from '@datorama/akita';
import { Channel } from '../../../../modules/shared/models/channel.model';
import { ChannelsQuery } from '../../../../modules/shared/state/channels/channels.query';
import { ErrorComponent } from '../../../../modules/shared/dialogs/error/error.component';
import { isElement } from 'lodash-es';
import { FlashMessageService } from '../../../../modules/core/services/flash-message.service';
import { TreeItem, TreeUtilsService } from '../../services/utilities/tree-utils.service';
import { UsersQuery } from '../../../../modules/shared/state/users/users.query';
import { StylesheetQuery } from '../../state/stylesheet/stylesheet.query';
import { PreviewDto } from '../../models/dto/preview-dto.model';
import { NodesStore } from '../../state/nodes/nodes.store';

@Component({
    selector: 'elias-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['editor.component.scss'],
})
export class EditorComponent implements OnInit, OnDestroy {
    @HostBinding('class') classes = 'app-body';

    @ViewChild('propertyEditor') private propertyEditor: PropertyEditorComponent;

    @ViewChild(TreeComponent) private treeComponent: TreeComponent;

    treeOptions: ITreeOptions;
    sections$: Observable<TreeItem<Section>[]>;
    selectedNode$: Observable<Node>;
    activeSection$: Observable<Section>;
    nodePresets$: Observable<NodePreset[]>;
    channels$: Observable<Channel[]>;
    loading$: Observable<boolean>;
    activeSection: Section;
    selectedElement$: Observable<SelectedElement>;
    selectedElement;
    documentLoading$: Observable<boolean>;
    generatingPreview$: Observable<boolean>;
    userId: ID;
    userLockedSection: ID;
    focusedTreeNodeId: string;
    loaded$ = new BehaviorSubject<boolean>(false);
    subscriptions: Subscription[] = [];
    toggleView = false;
    styleTag = null;
    user;

    constructor(
        private aspectRatiosQuery: AspectRatiosQuery,
        private aspectRatiosService: AspectRatiosService,
        private channelsQuery: ChannelsQuery,
        private channelsService: ChannelsService,
        private chartsService: ChartsService,
        private cycleService: CycleService,
        public dialog: MatDialog,
        private documentService: DocumentService,
        private editorService: EditorService,
        private flashMessageService: FlashMessageService,
        private locksQuery: LocksQuery,
        private locksService: LocksService,
        private nodePresetsQuery: NodePresetsQuery,
        private nodePresetsService: NodePresetsService,
        private nodesQuery: NodesQuery,
        private nodesStore: NodesStore,
        private pipeReplacerService: TitlePipeReplacerService,
        private previewService: PreviewService,
        private previewsQuery: PreviewQuery,
        private propertyEditorQuery: PropertyEditorQuery,
        private propertyEditorService: PropertyEditorService,
        private publicationsQuery: PublicationsQuery,
        private route: ActivatedRoute,
        private router: Router,
        private sectionService: SectionsService,
        private sectionsQuery: SectionsQuery,
        private sectionsService: SectionsService,
        private stylesheetQuery: StylesheetQuery,
        private treeService: TreeService,
        private treeUtilsService: TreeUtilsService,
        private uiService: UiService,
        private usersQuery: UsersQuery
    ) {
        this.nodePresets$ = this.nodePresetsQuery.selectAll();
        this.documentLoading$ = this.documentService.loading$;
    }

    ngOnInit() {
        this.loadData();
        this.loadStylesheet();
        this.uiService.setToolbarState('editor');

        this.user = this.usersQuery.getLoggedInUser();
        this.userId = this.user.id;
        this.setUserLock();

        this.channels$ = this.channelsQuery.selectAll();
        this.sections$ = this.sectionsQuery.selectAll().pipe(
            filter((sections) => sections.length > 0),
            map((sections) => this.treeUtilsService.createTreeFromArray<Section>(sections)),
            map((sections) => sections[0].children)
        );
        // TODO: loading observable
        this.loading$ = of(false);
        this.selectedNode$ = this.nodesQuery.select('selectedNode');
        this.activeSection$ = this.sectionsQuery.selectActive() as Observable<Section>;
        this.generatingPreview$ = this.previewsQuery.selectLoading();
        this.treeOptions = this.treeService.getTreeOptions();
        this.selectedElement$ = this.propertyEditorQuery.select('selectedElement');

        this.selectedElement$.pipe(delay(0)).subscribe((selectedElement: SelectedElement) => {
            this.selectedElement = selectedElement;
        });

        this.subscriptions.push(
            this.activeSection$.subscribe((section: Section) => {
                if (section) {
                    if (this.activeSection === undefined) {
                        this.treeService.loadAssets(section.id);
                    }
                    this.activeSection = section;
                }
            })
        );

        // Cycle

        this.subscriptions.push(
            interval(15000).subscribe(() => {
                this.cycleService.updateContent().subscribe((response) => {
                    if (response.body?.redirectUrl !== undefined) {
                        const returnUrl = response.body.redirectUrl;
                        this.router.navigate([returnUrl], { relativeTo: this.route });
                    }
                    if (response.body?.locks) {
                        this.locksService.resetLocks(response.body.locks);
                        this.setUserLock();
                    }
                    if (response.body?.nodes) {
                        response.body.nodes.forEach((node) => {
                            // Restriction: not prepared for editor opened in multiple tabs
                            const toUpdate = this.nodesQuery.getEntity(node.id);
                            if (toUpdate) {
                                this.nodesStore.update(node.id, node);
                            }
                        });
                    }
                    if (response.body?.charts) {
                        response.body.charts.forEach((chart) => {
                            this.chartsService.updateChart(chart);
                        });
                    }

                    if (response.body?.sections) {
                        const activeSectionId = this.sectionsQuery.getActiveId();
                        this.sectionsService.resetSections(response.body.sections);
                        this.sectionService.setActiveSectionById(activeSectionId);
                        this.sectionsService.updateLocks();
                    }
                });
            })
        );

        this.loaded$
            .pipe(
                delay(50),
                tap((loaded) => {
                    if (loaded) {
                        const sectionId = this.sectionsQuery.getActiveId();
                        if (this.treeComponent && this.treeComponent.treeModel && sectionId) {
                            let node = this.treeComponent.treeModel.getNodeById(sectionId);
                            if (node) {
                                node.setActiveAndVisible(true);
                            }
                        }

                        // Remove tree node focus
                        this.treeService.shouldRemoveFocus$.subscribe((sectionId: string) => {
                            if (this.treeComponent && this.treeComponent.treeModel) {
                                if (this.treeComponent.treeModel.getFocusedNode()) {
                                    this.treeComponent.treeModel.getFocusedNode().blur();
                                }
                                this.focusedTreeNodeId = '';
                            }
                        });

                        this.treeService.shouldSelect$.subscribe((sectionId: ID) => {
                            if (this.treeComponent && this.treeComponent.treeModel) {
                                this.treeComponent.treeModel.getActiveNodes().forEach((node, index) => {
                                    this.treeComponent.treeModel.setActiveNode(node, false);
                                });
                            }
                            setTimeout(() => {
                                if (this.treeComponent && this.treeComponent.treeModel) {
                                    let node = this.treeComponent.treeModel.getNodeById(sectionId);
                                    if (node) {
                                        node.setActiveAndVisible(true);
                                        if (!this.activeSection || this.activeSection.id != sectionId)
                                            this.saveOpenElementAndGoToSection(sectionId as string);
                                    }
                                }
                            }, 100);
                        });

                        // Select tree node
                        /* this.treeService.shouldSelect$.subscribe((sectionId: string) => {
                            console.log(sectionId);
                            /!*this.treeComponent.treeModel.getActiveNodes().forEach((node, index) => {
                                this.treeComponent.treeModel.setActiveNode(node, false);
                            });*!/
                            if (this.treeComponent.treeModel.getNodeById(sectionId)) {
                                this.treeComponent.treeModel.getNodeById(sectionId).setActiveAndVisible(true);
                            }
                        });*/
                    }
                })
            )
            .subscribe();
    }

    public async onGenerateWordPreview(previewDto: PreviewDto): Promise<void> {
        await this.nodesQuery.waitUntilAllSaved();
        this.generatePreview(previewDto);
    }

    public replacePipes(stringToReplace: string): string {
        return this.pipeReplacerService.replacePipes(stringToReplace, false);
    }

    public isLastTopLevelNode(node: TreeNode): boolean {
        return node.level === 1 && node.parent?.children?.length === 1;
    }

    public addChildSection(options: TreeNode): void {
        this.treeService.onCreateSection(options.data);
    }

    public addNextSection(options: TreeNode): void {
        this.treeService.onCreateSection(options.data, true);
    }

    public deleteSection(options: TreeNode): void {
        this.treeService.onDelete(this.treeComponent, options.data);
    }

    public async copySection(options: TreeNode): Promise<void> {
        if (options.data.id == options.data.rootId) {
            const dialogRef = this.dialog.open(ErrorComponent, {
                data: {
                    type: 'root',
                },
            });
        } else {
            await this.nodesQuery.waitUntilAllSaved();
            this.treeService.onCopy(this.treeComponent, options.data);
        }
    }

    public stopEvent(event: Event): void {
        event.stopPropagation();
    }

    public toggle(): void {
        this.toggleView = !this.toggleView;
    }

    private loadData(): void {
        const observables = [];
        const publication = this.publicationsQuery.getActive() as Publication;

        if (!this.locksQuery.getValue().loaded) {
            if (publication) {
                observables.push(this.locksService.getLocksForPublication(publication.id as string));
            }
        }

        if (!this.nodePresetsQuery.getValue().loaded) {
            observables.push(this.nodePresetsService.getNodePresets());
        }

        if (!this.propertyEditorQuery.getValue().loaded) {
            if (publication) {
                observables.push(this.propertyEditorService.loadPropertyEditorConfigurations(publication.id as string));
            }
        }

        if (!this.channelsQuery.getValue().loaded) {
            observables.push(this.channelsService.getChannelsForPublication(publication.id as string));
        }

        if (!this.aspectRatiosQuery.getValue().loaded) {
            observables.push(this.aspectRatiosService.getAspectRatios());
        }

        if (observables.length > 0) {
            this.loaded$.next(false);
            forkJoin(observables)
                .pipe(first())
                .subscribe((results) => {
                    this.loaded$.next(true);
                });
        } else {
            this.loaded$.next(true);
        }
    }

    /**
     * Add style tag to DOM containing the customer style configuration provided
     * by the backend.
     */
    private loadStylesheet(): void {
        const styleConfiguration = this.stylesheetQuery.getValue().stylesheet;
        const style = document.createElement('style');
        style.appendChild(document.createTextNode(styleConfiguration));
        document.head.appendChild(style);

        this.styleTag = style;
    }

    /**
     * Remove style tag from DOM
     */
    private unloadStylesheet(): void {
        if (isElement(this.styleTag)) {
            this.styleTag.remove();
        }
    }

    private generatePreview(previewDto: PreviewDto): void {
        const payload: RequestDto = new RequestDto({
            sectionId: this.activeSection.id,
            channelId: previewDto.channelId,
        });

        if (previewDto.renderer === 'word') {
            this.previewService.getWordSectionPreview(payload).subscribe(
                (blob) => {
                    const publication = this.publicationsQuery.getActive() as Publication;
                    this.previewService.downloadFile(publication, previewDto, blob);
                },
                (err) => {
                    this.flashMessageService.showTranslatedError('system.channel.previewerror');
                }
            );
        } else {
            this.previewService.getHtmlSectionPreview(payload).subscribe((content) => {
                this.dialog.open(PreviewHtmlComponent, {
                    data: { content, channelId: previewDto.channelId },
                    width: '1170px',
                    height: '900px',
                });
            });
        }
    }

    private setUserLock(): void {
        this.locksQuery.selectAll().subscribe((locks) => {
            locks.forEach((lock) => {
                if (lock.user.id === this.userId) {
                    this.userLockedSection = lock.section.id;
                }
            });
        });
    }

    private goToSection(sectionId: string): void {
        this.focusedTreeNodeId = sectionId;
        this.treeComponent.treeModel.getActiveNodes().forEach((node, index) => {
            this.treeComponent.treeModel.setActiveNode(node, false);
        });
        const publication = this.publicationsQuery.getActive() as Publication;
        this.router.navigate([
            '/publication-groups',
            publication.publicationGroup.id,
            'publications',
            publication.id,
            'editor',
            'sections',
            sectionId,
        ]);
    }

    private saveOpenElementAndGoToSection(sectionId: string): void {
        if (sectionId === this.focusedTreeNodeId) {
            return;
        }

        this.goToSection(sectionId);
    }

    @HostListener('unloaded')
    ngOnDestroy(): void {
        this.uiService.setToolbarState('overview');

        this.editorService.resetStores(this.activeSection);
        this.subscriptions.forEach((subscription) => {
            if (subscription) {
                subscription.unsubscribe();
            }
        });

        this.unloadStylesheet();
    }
}
