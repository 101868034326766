import { Injectable } from '@angular/core';
import { FormConfig } from '../../../modules/forms/models/form-config.model';
import { PublicationsQuery } from '../../../publication/state/publications/publications.query';
import { LayoutService } from '../services/layout.service';

@Injectable({
    providedIn: 'root',
})
export class NewSectionForm {
    constructor(private layoutService: LayoutService, private publicationsQuery: PublicationsQuery) {}

    public getConfiguration(): FormConfig {
        return {
            elements: [
                {
                    name: 'sectionName',
                    type: 'text',
                    label: 'modal.createSection.title',
                    width: 100,
                    required: true,
                    validators: [
                        {
                            name: 'maxLength',
                            options: { length: 70 },
                        },
                    ],
                },
                {
                    name: 'sectionSubtitle',
                    type: 'text',
                    label: 'modal.createSection.subtitle',
                    required: false,
                    width: 100,
                    settings: {},
                    validators: [
                        {
                            name: 'maxLength',
                            options: { length: 70 },
                        },
                    ],
                },
                ...this.getSectionTypeElement(),
            ],
        };
    }

    private getSectionTypeElement(): FormConfig['elements'] {
        const sectionTypeOptions = this.layoutService.getSectionTypeOptions(this.publicationsQuery.getActive());

        if (sectionTypeOptions.length < 2) {
            return [];
        }

        const defaultSectionTypeOption =
            sectionTypeOptions.find((keyValueOption) => keyValueOption.key === 'general') ?? sectionTypeOptions[0];

        return [
            {
                name: 'sectionType',
                type: 'select',
                label: 'modal.createSection.type',
                options: sectionTypeOptions,
                value: defaultSectionTypeOption.key,
                required: true,
            },
        ];
    }
}
